/* Theme */
$color-primary-darker: hsla(200, 96%, 18%, 1);
$color-primary-dark: hsla(200, 93%, 22%, 1);
$color-primary-base: hsla(200, 90%, 35%, 1);
$color-primary-light: hsla(200, 60%, 92%, 1);
$color-primary-lighter: hsla(200, 60%, 98%, 1);

$color-secondary-darker: hsla(20, 95%, 50%, 1);
$color-secondary-dark: hsla(25, 95%, 60%, 1);
$color-secondary-base: hsla(30, 95%, 70%, 1);
$color-secondary-light: hsla(32, 95%, 92%, 1);
$color-secondary-lighter: hsla(32, 95%, 98%, 1);

$color-monochrome-darker: hsla(20, 10%, 30%, 1);
$color-monochrome-dark: hsla(25, 10%, 45%, 1);
$color-monochrome-base: hsla(30, 10%, 60%, 1);
$color-monochrome-light: hsla(32, 10%, 92%, 1);
$color-monochrome-lighter: hsla(35, 10%, 98%, 1);

$color-main-theme-light: #3a7ca5;
$color-main-theme-lighter: #81c3d7;
$color-main-theme-lighterer: #d9dcd6;
$color-main-theme-lightest: #edefec;

$color-toolbar-button: hsla(200, 96%, 9%, 1);;
$color-control-background: #fff;

$color-invalid-lighter: pink;
$color-invalid-darker: red;

$color-danger: #dc3545;

$color-drop-active: green;

$color-active: green;

$desktop-small-width: 1920px;

$search-status-default: darkorange;

$color-gas-safe: #fff200;
$color-gas-safe-text: #000000;

$color-multi-delete:#d1ecf1;

$color-text-warning: #ff8c00;

@mixin desktop-small {
  @media (max-width: #{$desktop-small-width}) {
    @content;
  }
}

@mixin desktop-large {
  @media (min-width: #{$desktop-small-width + 1}) {
    @content;
  }
}
