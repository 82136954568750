h5.map-toolbar-group {
  padding-top: 0.7rem !important;
}

.map-block-toolbar {
  background: $color-monochrome-lighter;
  width: 100%;
  height: 3rem;

  ul {
    padding-inline-start: 0;
  }

  li {
    list-style: none;
  }

  .map-toolbar-group {
    display: inline-block;
    padding: 0;
    float: left;
    text-align: left;
    margin-right: 0.5rem;
    margin-bottom: 0;

    &.is-type-settings {
      margin-right: 0;
    }

    &.is-style-blue {
      .map-toolbar-item {
        color: $color-primary-base;
      }
    }

    &.is-style-orange {
      .map-toolbar-item {
        color: $color-secondary-dark;
      }
    }

    .map-toolbar-sublist {
      padding: 0;
    }

    .map-toolbar-item {
      background: $color-monochrome-lighter;
      border: none;
      outline: none;
      display: inline-block;
      float: left;
      cursor: pointer;
      position: relative;
      padding: 0;

      &:disabled {
        color: $color-monochrome-light;
      }

      &:hover {
        background: $color-monochrome-light;
      }

      &.is-status-active {
        background: $color-primary-base;
        color: white;
        cursor: default;
      }

      &.is-status-disable {
        color: $color-monochrome-light;
        cursor: default;

        &:hover {
          background: $color-monochrome-lighter;
        }
      }

      &:before {
        font-family: 'iconsets' !important;
        font-size: 2rem;
        margin: 0 0.5rem;
      }

      &.is-icon-cursor-pointer:before {
        content: '\ea03';
      }

      &.is-icon-draw-house:before {
        content: '\e952';
      }

      &.is-icon-draw-road:before {
        content: '\e957';
      }

      &.is-icon-edit-undo:before {
        content: '\e958';
      }

      &.is-icon-edit-redo:before {
        content: '\e953';
      }

      &.is-icon-edit-delete:before {
        content: '\e954';
      }

      &.is-icon-edit-center:before {
        content: '\e95a';
      }

      &.is-icon-edit-location:before {
        content: '\e942';
      }

      &.is-icon-edit-reset:before {
        content: '\e94b';
      }

      &.is-icon-edit-layers:before {
        content: '\e956';
      }

      &.is-icon-add:before {
        content: '\e98f';
      }

      &.is-icon-map-street-draw:before {
        content: '\e9af';
      }

      &.is-icon-generic-tick:before {
        content: '\e983';
      }

      &.is-icon-delete:before {
        content: '\e9c0';
      }

      &.is-icon-cancel-cross:before {
        content: '\e9c2';
      }

      &.is-icon-share:before {
        content: '\e9af';
      }
    }
  }

  .is-type-settings {
    display: inline-block;
    float: right;

    & button {
      position: relative;

      & > ul {
        display: none;
        position: absolute;
        right: 0;
        width: 14rem;
        z-index: 1;
        background: $color-monochrome-lighter;

        & > li {
          position: relative;

          .edit-layer-list-item {
            padding: 0.5rem 1rem 0.5rem 3rem;
            display: block;
            color: $color-primary-base;
            text-decoration: none;
            position: relative;

            &:before {
              font-family: 'iconsets' !important;
              font-size: 1.5rem;
              position: absolute;
              left: 12px;
              top: 2px;
            }

            &.is-status-enabled:before {
              color: green;
              content: '\e983';
            }

            &.is-status-disabled:before {
              color: red;
              content: '\e9c2';
            }

            &.is-status-locked:before {
              color: goldenrod;
              content: '\ea31';
            }
          }

          &:hover {
            background: $color-monochrome-light;
          }
        }
      }

      &:hover > ul {
        display: block;
      }
    }
  }
}
