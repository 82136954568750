@import 'bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css?family=Lato');
@import url('https://use.fontawesome.com/releases/v6.2.1/css/all.css');
@import '@ng-select/ng-select/themes/default.theme.css';
@import 'iconsets/iconsets.scss';
$fa-font-path: 'font-awesome/fonts';

@import 'ol/ol.css';

@import 'theme';
@import 'maps';
@import 'openlayers';
@import 'sections';

html,
body {
  height: 100vh;
  width: 100vw;
  font-size: 11pt;
}

::placeholder {
  color: #adb5bd !important;
}

.input-group-text {
  background-color: #e9ecef;
  border: 1px solid #ced4da;
}

.composite-control {
  display: block;

  &.ng-invalid {
    border: 2px solid red;
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

    &:focus {
      border-color: #dc3545;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  }

  .ng-invalid {
    border: none;
    padding: 0;
    background: none;
  }

  input,
  input:focus {
    outline: none;
    box-shadow: none;
  }
}

.popover {
  max-width: unset !important;
}

fw-composite-input {
  .form-control {
    height: unset;

    &:focus {
      box-shadow: none;
    }
  }

  button.btn:focus {
    box-shadow: none;
  }
}

fw-board-search-clerk {
  .tooltip {
    margin-top: -2rem;
  }

  .tooltip-inner {
    background-color: white;
    border-radius: 0.5rem;
    padding: 0 0 0.5rem 0;
    border: 0.1rem solid $color-primary-base;
  }

  .arrow {
    visibility: hidden;
  }

  .bs-tooltip-bottom {
    padding: 0;
  }
}

.modal-header {
  background-color: $color-primary-dark;
  color: $color-monochrome-light;
  font-size: 1.3rem;

  .modal-title {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;

    button {
      color: $color-monochrome-light;

      &:hover {
        color: $color-monochrome-lighter;
      }

      background-color: $color-primary-dark;
      line-height: 1rem;
      font-size: 2rem;
      margin: 0 0.3rem;
      padding: 0;
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
}

.ng-select.left-pictogram {
  >.ng-select-container {
    height: 100%;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.ng-invalid>.tox-tinymce {
  border: 1px solid Red !important;
}

.ng-select.right-pictogram {
  >.ng-select-container {
    height: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.ng-select.toolbar-mode {
  >.ng-select-container {
    border: none;
  }
}

.ng-select {
  &.ng-select-multiple {
    .ng-select-container {
      height: 100%;
      border: 0;
      border-radius: 0;

      .ng-value-container {
        padding-top: 0;

        .ng-placeholder {
          position: unset;
          top: unset;
          padding: 0;
        }
      }
    }
  }
}

input,
textarea {
  &.ng-invalid {
    border-color: $color-invalid-darker;
  }
}

ng-select.ng-invalid {
  .ng-select-container {
    border: 1px solid $color-invalid-darker;
  }
}

.rlas-invalid {
  border: 1px solid $color-invalid-darker;
}

.dragging {
  z-index: 1000;
}

.unrestricted-tooltip .tooltip-inner {
  max-width: unset;
}

.ngb-tp {
  .ngb-tp-input-container {
    width: initial;

    .ngb-tp-input {
      padding: 0em;
    }
  }

  .ngb-tp-spacer {
    width: 2em !important;
  }
}

h6,
.h6 {
  line-height: 1.5;
}

.custom-width {
  .modal-dialog {
    max-width: 70%;
    max-height: 80%;
  }
}

.icon-overview:before {
  content: '\e9b5';
}

.icon-general:before {
  content: '\ea06';
}

.icon-departments:before {
  content: '\e95f';
}

.icon-default-answers:before {
  content: '\e9e7';
}

.icon-help:before {
  content: '\e9cd';
}

.icon-data-rules:before {
  content: '\e9c5';
}

.icon-shortcodes:before {
  content: '#';
}

.icon-map-layers:before {
  content: '\e9ce';
}

.icon-history:before {
  content: '\ea01';
}

.icon-kaizen-help:before {
  content: '\ea61';
}

.icon-pencil-data-rules:before {
  content: '\e9bf';
}

.icon-harts-data-rules:before {
  content: '\e9be';
}

.icon-agent-data-rules:before {
  content: '\ea09';
}

.icon-personal-data-rules:before {
  content: '\ea0d';
}

.icon-mapping:before {
  content: '\ea63';
}

.icon-searcher-allocation:before {
  content: '\e9be';
}

.icon-searcher-allocation-clerk:before {
  content: '\ea09';
}

.icon-hour-glass:before {
  content: '\ea57';
}

.icon-development-help:before {
  content: '\e972';
}

.icon-split-config:before {
  font-family: 'Font Awesome 6 Free';
  font-weight: 900;
  content: '\e4bc';
}

.icon-default-documents:before {
  content: '\e911';
}

.edit-footer {
  flex: none;
  padding: 0.5rem;
  border-top: 1px solid $color-primary-dark;
  text-align: right;

  .spaced-left {
    >* {
      margin-left: 1rem;
    }
  }
}

.icon-data-refresh:before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f1da';
}

.icon-toolbox:before {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  content: '\f552';
}

.btn-link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;

  &:hover {
    background-color: #0069d9;
    border-color: #0062cc;
  }

  &:disabled,
  &.disabled {
    background-color: #007bff;
    border-color: #007bff;
  }
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;

  &:hover {
    background-color: #5a6268;
    border-color: #5a6268;
  }
}

.row {
  margin-left: -15px;
  margin-right: -15px;

  .col-sm-9,
  .col-sm-8,
  .col-sm-3,
  .col-sm-1,
  .col,
  .col-12,
  .col-11,
  .col-10,
  .col-9,
  .col-8,
  .col-7,
  .col-6,
  .col-5,
  .col-4,
  .col-3,
  .col-2,
  .col-1 {
    padding-right: 15px;
    padding-left: 15px;
  }
}

rm-user-menu {
  .dropdown-menu {
    transform: translate(0px, 23px) !important;
  }
}

rm-kaizen-collapsible-area {

  .col-11,
  .col-9,
  .col-2,
  .col-1 {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.btn-success {
  background-color: #28a745;
  border-color: #28a745;

  &:hover {
    background-color: #218838;
    border-color: #1e7e34;
  }
}

.accordion-header {
  background-color: #00000008;
  display: inline-block;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}


rm-details-pane,
rm-scheme-details {

  .btn:disabled,
  .btn:hover,
  button:not(:disabled),
  [type=button]:not(:disabled) {
    border: 0;
  }
}

rm-conservation-areas-details,
rm-scheme-details,
rm-local-plan-name-details,
rm-cil-details {

  .form-control:disabled,
  .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
  }
}

.bg-success.progress-bar {
  color: #fff;
  background-color: #28a745 !important;
}

.btn-training {
  --bs-btn-color: #fff;
  --bs-btn-bg: hsl(20, 95%, 50%);
  --bs-btn-border-color: hsl(20, 95%, 50%);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: hsl(20, 95%, 75%);
  --bs-btn-hover-border-color: hsl(20, 95%, 60%);
  --bs-btn-focus-shadow-rgb:
    130,
    138,
    145;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: hsl(20, 95%, 60%);
  --bs-btn-active-border-color: hsl(20, 95%, 70%);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: hsl(20, 95%, 50%);
  --bs-btn-disabled-border-color: hsl(20, 95%, 50%);
}

.settings-title {
  font-size: 1rem;
  font-weight: 600 !important;
  color: #000 !important;
  padding: 0.4rem 0;
}

.warning-text {
  color: $color-text-warning;
}

label {
  color: $color-monochrome-dark;
  font-weight: bold;
}

.spinner {
  height: 1.5em;
  width: 1.5em;
}
