/* Open Layers */

.ol-attribution {
  &.ol-control {
    background: none;

    button {
      background: $color-primary-base;
      border-radius: 50%;
    }
  }
}

.ol-zoom {
  &.ol-control {
    background: none;

    button {
      background: $color-primary-base;
      cursor: pointer;
      height: 28px;
      width: 28px;
      font-size: 1.5rem;

      &:before {
        color: white;
        font-weight: 300;
      }
    }
  }

  .ol-zoom-in {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  .ol-zoom-out {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}
