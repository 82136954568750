.section-title-text {
  color: $color-monochrome-darker;
}

.section-title-icon {
  color: $color-monochrome-dark;
  font-family: 'iconsets' !important;
  font-size: 2rem;
  position: relative;
  float: left;
  top: -3px;
  margin-right: 0.5rem;
  margin-left: 1rem;
}
