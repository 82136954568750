@font-face {
  font-family: 'iconsets';
  src: url('/assets/iconsets.eot?i1kyid');
  src: url('/assets/iconsets.eot?i1kyid#iefix') format('embedded-opentype'),
    url('/assets/iconsets.ttf?i1kyid') format('truetype'), url('/assets/iconsets.woff?i1kyid') format('woff'),
    url('/assets/iconsets.svg?i1kyid#iconsets') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconsets' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-wecare-add:before {
  content: '\e900';
}
.icon-wecare-add-1:before {
  content: '\e901';
}
.icon-wecare-add-new-document:before {
  content: '\e902';
}
.icon-wecare-alarm-clock:before {
  content: '\e903';
}
.icon-wecare-approve-invoice:before {
  content: '\e904';
}
.icon-wecare-attachment:before {
  content: '\e905';
}
.icon-wecare-calendar:before {
  content: '\e906';
}
.icon-wecare-checked:before {
  content: '\e907';
}
.icon-wecare-contract:before {
  content: '\e908';
}
.icon-wecare-danger:before {
  content: '\e909';
}
.icon-wecare-delete:before {
  content: '\e90a';
}
.icon-wecare-diamond-store:before {
  content: '\e90b';
}
.icon-wecare-document:before {
  content: '\e90c';
}
.icon-wecare-email:before {
  content: '\e90d';
}
.icon-wecare-expand:before {
  content: '\e90e';
}
.icon-wecare-expand-arrow:before {
  content: '\e90f';
}
.icon-wecare-expand-button:before {
  content: '\e910';
}
.icon-wecare-file:before {
  content: '\e911';
}
.icon-wecare-files-empty:before {
  content: '\e912';
}
.icon-wecare-filter-results-button:before {
  content: '\e913';
}
.icon-wecare-folder:before {
  content: '\e914';
}
.icon-wecare-frontIcon:before {
  content: '\e915';
}
.icon-wecare-fullscreen:before {
  content: '\e916';
}
.icon-wecare-garbage:before {
  content: '\e917';
}
.icon-wecare-home:before {
  content: '\e918';
}
.icon-wecare-ic_check_circle_48px:before {
  content: '\e919';
}
.icon-wecare-icon:before {
  content: '\e91a';
}
.icon-wecare-information:before {
  content: '\e91b';
}
.icon-wecare-list:before {
  content: '\e91c';
}
.icon-wecare-lock:before {
  content: '\e91d';
}
.icon-wecare-lock1:before {
  content: '\e91e';
}
.icon-wecare-lock-open:before {
  content: '\e91f';
}
.icon-wecare-lock-open1:before {
  content: '\e920';
}
.icon-wecare-login:before {
  content: '\e921';
}
.icon-wecare-login-square-arrow-button-outline:before {
  content: '\e922';
}
.icon-wecare-loop2:before {
  content: '\e923';
}
.icon-wecare-magnifier:before {
  content: '\e924';
}
.icon-wecare-mail:before {
  content: '\e925';
}
.icon-wecare-moon:before {
  content: '\e926';
}
.icon-wecare-notebook:before {
  content: '\e927';
}
.icon-wecare-notebook-1:before {
  content: '\e928';
}
.icon-wecare-notification:before {
  content: '\e929';
}
.icon-wecare-notification-1:before {
  content: '\e92a';
}
.icon-wecare-notification-2:before {
  content: '\e92b';
}
.icon-wecare-pencil:before {
  content: '\e92c';
}
.icon-wecare-phone-call:before {
  content: '\e92d';
}
.icon-wecare-placeholder:before {
  content: '\e92e';
}
.icon-wecare-placeholder-1:before {
  content: '\e92f';
}
.icon-wecare-placeholder-filled-point:before {
  content: '\e930';
}
.icon-wecare-rating-1:before {
  content: '\e931';
}
.icon-wecare-relation-diagram:before {
  content: '\e932';
}
.icon-wecare-send:before {
  content: '\e933';
}
.icon-wecare-share:before {
  content: '\e934';
}
.icon-wecare-share-option:before {
  content: '\e935';
}
.icon-wecare-share-post-symbol:before {
  content: '\e936';
}
.icon-wecare-slumber:before {
  content: '\e937';
}
.icon-wecare-speech-bubble:before {
  content: '\e938';
}
.icon-wecare-support:before {
  content: '\e939';
}
.icon-wecare-time-left:before {
  content: '\e93a';
}
.icon-wecare-turn-notifications-on-button:before {
  content: '\e93b';
}
.icon-wecare-users:before {
  content: '\e93c';
}
.icon-wecare-timeline:before {
  content: '\e93d';
}
.icon-wecare-harts:before {
  content: '\e93e';
}
.icon-wecare-building:before {
  content: '\e93f';
}
.icon-action-cancel:before {
  content: '\e940';
}
.icon-action-info:before {
  content: '\e941';
}
.icon-action-pinned:before {
  content: '\e942';
}
.icon-action-warning:before {
  content: '\e943';
}
.icon-button-cancel:before {
  content: '\e944';
}
.icon-button-pin:before {
  content: '\e945';
}
.icon-button-preview:before {
  content: '\e946';
}
.icon-button-write:before {
  content: '\e947';
}
.icon-complete-purchase:before {
  content: '\e948';
}
.icon-confirm-purchase:before {
  content: '\e949';
}
.icon-map-boundary:before {
  content: '\e94a';
}
.icon-map-cancel-area:before {
  content: '\e94b';
}
.icon-mini-zoom-in:before {
  content: '\e94c';
}
.icon-wecare-history:before {
  content: '\e94d';
}
.icon-mini-zoom-out:before {
  content: '\e94e';
}
.icon-map-measure:before {
  content: '\e94f';
}
.icon-lock:before {
  content: '\e950';
}
.icon-headset:before {
  content: '\e951';
}
.icon-map-property-draw:before {
  content: '\e952';
}
.icon-map-redo:before {
  content: '\e953';
}
.icon-map-reset:before {
  content: '\e954';
}
.icon-map-select-area:before {
  content: '\e955';
}
.icon-map-settings:before {
  content: '\e956';
}
.icon-map-street-draw:before {
  content: '\e957';
}
.icon-map-undo:before {
  content: '\e958';
}
.icon-map-upload-plan:before {
  content: '\e959';
}
.icon-map-zoom-center:before {
  content: '\e95a';
}
.icon-map-zoom-in:before {
  content: '\e95b';
}
.icon-wecare-drawer:before {
  content: '\e95c';
}
.icon-map-zoom-out:before {
  content: '\e95d';
}
.icon-nav-account:before {
  content: '\e95e';
}
.icon-nav-home:before {
  content: '\e95f';
}
.icon-nav-orders:before {
  content: '\e960';
}
.icon-nav-reports:before {
  content: '\e961';
}
.icon-nav-search:before {
  content: '\e962';
}
.icon-nav-search-field:before {
  content: '\e963';
}
.icon-search-list:before {
  content: '\e964';
}
.icon-type-agriculture:before {
  content: '\e965';
}
.icon-wecare-floor-plan:before {
  content: '\e966';
}
.icon-wecare-undo2:before {
  content: '\e967';
}
.icon-wecare-generic-ticks:before {
  content: '\e968';
}
.icon-wecare-many-fields:before {
  content: '\e969';
}
.icon-wecare-map-money:before {
  content: '\e96a';
}
.icon-wecare-map-point:before {
  content: '\e96b';
}
.icon-wecare-map-stamp:before {
  content: '\e96c';
}
.icon-wecare-public-transport:before {
  content: '\e96d';
}
.icon-wecare-road-sides:before {
  content: '\e96e';
}
.icon-type-commercial-new:before {
  content: '\e96f';
}
.icon-type-commercial-sale:before {
  content: '\e970';
}
.icon-type-commercial-standard:before {
  content: '\e971';
}
.icon-type-residential-new:before {
  content: '\e972';
}
.icon-type-residential-standard:before {
  content: '\e973';
}
.icon-progress-arrow:before {
  content: '\e974';
}
.icon-phone:before {
  content: '\e975';
}
.icon-demo-board:before {
  content: '\e976';
}
.icon-section-small-arrow-down:before {
  content: '\e977';
}
.icon-section-small-arrow-left:before {
  content: '\e978';
}
.icon-wecare-hour-glass:before {
  content: '\e979';
}
.icon-section-small-arrow-right:before {
  content: '\e97a';
}
.icon-section-small-arrow-up:before {
  content: '\e97b';
}
.icon-button-save-cloud:before {
  content: '\e97c';
}
.icon-switch-back-to-map:before {
  content: '\e97d';
}
.icon-map-pin-area:before {
  content: '\e97e';
}
.icon-section-small-arrow-left-end:before {
  content: '\e97f';
}
.icon-section-small-arrow-right-end:before {
  content: '\e980';
}
.icon-wecare-spinner8:before {
  content: '\e981';
}
.icon-button-go-back:before {
  content: '\e982';
}
.icon-generic-tick:before {
  content: '\e983';
}
.icon-button-print:before {
  content: '\e984';
}
.icon-map-view-area:before {
  content: '\e985';
}
.icon-map-view-area-glass:before {
  content: '\e986';
}
.icon-product-aml:before {
  content: '\e987';
}
.icon-product-ap1:before {
  content: '\e988';
}
.icon-product-indemnity:before {
  content: '\e989';
}
.icon-product-pack:before {
  content: '\e98a';
}
.icon-product-sdlt:before {
  content: '\e98b';
}
.icon-product-searches:before {
  content: '\e98c';
}
.icon-product-settlement:before {
  content: '\e98d';
}
.icon-product-voi:before {
  content: '\e98e';
}
.icon-product-add-plus:before {
  content: '\e98f';
}
.icon-product-bankruptcy:before {
  content: '\e990';
}
.icon-product-landregistry:before {
  content: '\e991';
}
.icon-order-progress:before {
  content: '\e992';
}
.icon-order-warning:before {
  content: '\e993';
}
.icon-button-download:before {
  content: '\e994';
}
.icon-button-email:before {
  content: '\e995';
}
.icon-map-back-to-map:before {
  content: '\e996';
}
.icon-thumbsdown:before {
  content: '\e997';
}
.icon-thumbsup:before {
  content: '\e998';
}
.icon-multi-doc-discussion:before {
  content: '\e999';
}
.icon-multi-doc-locked:before {
  content: '\e99a';
}
.icon-multi-doc-progress:before {
  content: '\e99b';
}
.icon-multi-doc-standard:before {
  content: '\e99c';
}
.icon-attachment:before {
  content: '\e99d';
}
.icon-cloud-discussion:before {
  content: '\e99e';
}
.icon-calendar:before {
  content: '\e99f';
}
.icon-product-companychecker:before {
  content: '\e9a0';
}
.icon-product-generic:before {
  content: '\e9a1';
}
.icon-product-lawyerchecker:before {
  content: '\e9a2';
}
.icon-product-quotingtool:before {
  content: '\e9a3';
}
.icon-wecare-shrink:before {
  content: '\e9a4';
}
.icon-order-waiting:before {
  content: '\e9a5';
}
.icon-product-book-settlement-dollar:before {
  content: '\e9a6';
}
.icon-product-report-bug:before {
  content: '\e9a7';
}
.icon-product-book-settlement-pound:before {
  content: '\e9a8';
}
.icon-product-contract-sign:before {
  content: '\e9a9';
}
.icon-drag-and-drop:before {
  content: '\e9aa';
}
.icon-form-block:before {
  content: '\e9ab';
}
.icon-multi-doc-action-add:before {
  content: '\e9ac';
}
.icon-multi-doc-action-delete:before {
  content: '\e9ad';
}
.icon-multi-doc-action-update:before {
  content: '\e9ae';
}
.icon-share:before {
  content: '\e9af';
}
.icon-urgent-progress:before {
  content: '\e9b0';
}
.icon-empty-docs-holder:before {
  content: '\e9b1';
}
.icon-multi-doc-folder:before {
  content: '\e9b2';
}
.icon-switch-back-to-plan:before {
  content: '\e9b3';
}
.icon-multi-form-floor-plan:before {
  content: '\e9b4';
}
.icon-multi-form-generic-ticks:before {
  content: '\e9b5';
}
.icon-multi-form-many-fields:before {
  content: '\e9b6';
}
.icon-multi-form-map-money:before {
  content: '\e9b7';
}
.icon-wecare-clipboard:before {
  content: '\e9b8';
}
.icon-multi-form-map-point:before {
  content: '\e9b9';
}
.icon-multi-form-map-stamp:before {
  content: '\e9ba';
}
.icon-multi-form-public-transport:before {
  content: '\e9bb';
}
.icon-multi-form-road-sides:before {
  content: '\e9bc';
}
.icon-attach-files-compact:before {
  content: '\e9bd';
}
.icon-user-generated-content:before {
  content: '\e9be';
}
.icon-computer-generated-content:before {
  content: '\e9bf';
}
.icon-trashbin-compact:before {
  content: '\e9c0';
}
.icon-arrow-bullet:before {
  content: '\e9c1';
}
.icon-cancel-cross:before {
  content: '\e9c2';
}
.icon-screen-multi-view:before {
  content: '\e9c3';
}
.icon-screen-split-view:before {
  content: '\e9c4';
}
.icon-screen-typing-view:before {
  content: '\e9c5';
}
.icon-action-cancel-bin:before {
  content: '\e9c6';
}
.icon-action-refund:before {
  content: '\e9c7';
}
.icon-multi-doc-cancel:before {
  content: '\e9c8';
}
.icon-multi-doc-refund:before {
  content: '\e9c9';
}
.icon-wecare-earth:before {
  content: '\e9ca';
}
.icon-form-block-docs:before {
  content: '\e9cb';
}
.icon-form-block-files:before {
  content: '\e9cc';
}
.icon-form-block-help:before {
  content: '\e9cd';
}
.icon-form-block-map:before {
  content: '\e9ce';
}
.icon-bubble-arrow-right-down:before {
  content: '\e9cf';
}
.icon-bubble-arrow-right-up:before {
  content: '\e9d0';
}
.icon-calendar-edd-set:before {
  content: '\e9d1';
}
.icon-calendar-edd-set1:before {
  content: '\e9d2';
}
.icon-calendar-edd-set2:before {
  content: '\e9d3';
}
.icon-calendar-edd-set3:before {
  content: '\e9d4';
}
.icon-calendar-edd-set4:before {
  content: '\e9d5';
}
.icon-calendar-edd-set5:before {
  content: '\e9d6';
}
.icon-calendar-edd-set6:before {
  content: '\e9d7';
}
.icon-calendar-edd-set7:before {
  content: '\e9d8';
}
.icon-calendar-edd-set8:before {
  content: '\e9d9';
}
.icon-calendar-edd-set9:before {
  content: '\e9da';
}
.icon-council-booking:before {
  content: '\e9db';
}
.icon-email-arrow-right-down:before {
  content: '\e9dc';
}
.icon-email-arrow-right-up:before {
  content: '\e9dd';
}
.icon-email-unknown:before {
  content: '\e9de';
}
.icon-wecare-happy:before {
  content: '\e9df';
}
.icon-headset-arrow-right-down:before {
  content: '\e9e0';
}
.icon-wecare-smile:before {
  content: '\e9e1';
}
.icon-headset-arrow-right-up:before {
  content: '\e9e2';
}
.icon-headset-chased:before {
  content: '\e9e3';
}
.icon-order-accepted:before {
  content: '\e9e4';
}
.icon-wecare-sad:before {
  content: '\e9e5';
}
.icon-order-chased:before {
  content: '\e9e6';
}
.icon-order-completed:before {
  content: '\e9e7';
}
.icon-order-created:before {
  content: '\e9e8';
}
.icon-order-enquiry:before {
  content: '\e9e9';
}
.icon-order-submitted:before {
  content: '\e9ea';
}
.icon-phone-arrow-right-down:before {
  content: '\e9eb';
}
.icon-phone-arrow-right-up:before {
  content: '\e9ec';
}
.icon-response-questions:before {
  content: '\e9ed';
}
.icon-response-survey:before {
  content: '\e9ee';
}
.icon-wecare-assigned-team:before {
  content: '\e9ef';
}
.icon-wecare-snoozed:before {
  content: '\e9f0';
}
.icon-wecare-status-callback:before {
  content: '\e9f1';
}
.icon-wecare-status-new:before {
  content: '\e9f2';
}
.icon-wecare-status-progress:before {
  content: '\e9f3';
}
.icon-wecare-status-reopened:before {
  content: '\e9f4';
}
.icon-wecare-status-resolved:before {
  content: '\e9f5';
}
.icon-wecare-unsnoozed:before {
  content: '\e9f6';
}
.icon-wecare-neutral:before {
  content: '\e9f7';
}
.icon-add-notes:before {
  content: '\e9f8';
}
.icon-add-query:before {
  content: '\e9f9';
}
.icon-compare-screen-view:before {
  content: '\e9fa';
}
.icon-wecare-wondering:before {
  content: '\e9fb';
}
.icon-doc-save-cloud:before {
  content: '\e9fc';
}
.icon-docs-screen-view:before {
  content: '\e9fd';
}
.icon-extra-info:before {
  content: '\e9fe';
}
.icon-google-maps:before {
  content: '\e9ff';
}
.icon-help-screen-view:before {
  content: '\ea00';
}
.icon-previous-report:before {
  content: '\ea01';
}
.icon-query-screen-view:before {
  content: '\ea02';
}
.icon-doc-cursor-pointer:before {
  content: '\ea03';
}
.icon-restore-bin:before {
  content: '\ea04';
}
.icon-doc-info:before {
  content: '\ea05';
}
.icon-docs-company:before {
  content: '\ea06';
}
.icon-wecare-warning:before {
  content: '\ea07';
}
.icon-docs-conveyancer:before {
  content: '\ea08';
}
.icon-docs-individual:before {
  content: '\ea09';
}
.icon-docs-lender:before {
  content: '\ea0a';
}
.icon-doc-tick:before {
  content: '\ea0b';
}
.icon-doc-warning:before {
  content: '\ea0c';
}
.icon-label-conveyancer:before {
  content: '\ea0d';
}
.icon-label-trophy:before {
  content: '\ea0e';
}
.icon-action-filtering:before {
  content: '\ea0f';
}
.icon-action-refreshing:before {
  content: '\ea10';
}
.icon-action-sorting:before {
  content: '\ea11';
}
.icon-add-document:before {
  content: '\ea12';
}
.icon-add-user:before,
.icon-auto-allocations:before {
  content: '\ea13';
}
.icon-add:before {
  content: '\ea14';
}
.icon-add-1:before {
  content: '\ea15';
}
.icon-add-new-document:before {
  content: '\ea16';
}
.icon-alarm-clock:before {
  content: '\ea17';
}
.icon-approve-invoice:before {
  content: '\ea18';
}
.icon-attachment1:before {
  content: '\ea19';
}
.icon-calendar1:before {
  content: '\ea1a';
}
.icon-checked:before {
  content: '\ea1b';
}
.icon-contract:before {
  content: '\ea1c';
}
.icon-danger:before {
  content: '\ea1d';
}
.icon-delete:before {
  content: '\ea1e';
}
.icon-diamond-store:before {
  content: '\ea1f';
}
.icon-document:before {
  content: '\ea20';
}
.icon-email:before {
  content: '\ea21';
}
.icon-expand:before {
  content: '\ea22';
}
.icon-expand-arrow:before {
  content: '\ea23';
}
.icon-expand-button:before {
  content: '\ea24';
}
.icon-file:before {
  content: '\ea25';
}
.icon-files-empty:before {
  content: '\ea26';
}
.icon-filter-results-button:before {
  content: '\ea27';
}
.icon-folder:before {
  content: '\ea28';
}
.icon-frontIcon:before {
  content: '\ea29';
}
.icon-fullscreen:before {
  content: '\ea2a';
}
.icon-garbage:before {
  content: '\ea2b';
}
.icon-home:before {
  content: '\ea2c';
}
.icon-ic_check_circle_48px:before {
  content: '\ea2d';
}
.icon-icon:before {
  content: '\ea2e';
}
.icon-information:before {
  content: '\ea2f';
}
.icon-list:before {
  content: '\ea30';
}
.icon-lock1:before {
  content: '\ea31';
}
.icon-lock11:before {
  content: '\ea32';
}
.icon-lock-open:before {
  content: '\ea33';
}
.icon-wecare-arrow-right:before {
  content: '\ea34';
}
.icon-lock-open1:before {
  content: '\ea35';
}
.icon-login:before {
  content: '\ea36';
}
.icon-login-square-arrow-button-outline:before {
  content: '\ea37';
}
.icon-loop2:before {
  content: '\ea38';
}
.icon-magnifier:before {
  content: '\ea39';
}
.icon-mail:before {
  content: '\ea3a';
}
.icon-moon:before {
  content: '\ea3b';
}
.icon-wecare-arrow-right2:before {
  content: '\ea3c';
}
.icon-notebook:before {
  content: '\ea3d';
}
.icon-notebook-1:before {
  content: '\ea3e';
}
.icon-notification:before {
  content: '\ea3f';
}
.icon-notification-1:before {
  content: '\ea40';
}
.icon-notification-2:before {
  content: '\ea41';
}
.icon-pencil:before {
  content: '\ea42';
}
.icon-phone-call:before {
  content: '\ea43';
}
.icon-placeholder:before {
  content: '\ea44';
}
.icon-placeholder-1:before {
  content: '\ea45';
}
.icon-placeholder-filled-point:before {
  content: '\ea46';
}
.icon-rating-1:before {
  content: '\ea47';
}
.icon-relation-diagram:before {
  content: '\ea48';
}
.icon-send:before {
  content: '\ea49';
}
.icon-share1:before {
  content: '\ea4a';
}
.icon-share-option:before {
  content: '\ea4b';
}
.icon-share-post-symbol:before {
  content: '\ea4c';
}
.icon-slumber:before {
  content: '\ea4d';
}
.icon-speech-bubble:before {
  content: '\ea4e';
}
.icon-support:before {
  content: '\ea4f';
}
.icon-time-left:before {
  content: '\ea50';
}
.icon-turn-notifications-on-button:before {
  content: '\ea51';
}
.icon-users:before {
  content: '\ea52';
}
.icon-timeline:before {
  content: '\ea53';
}
.icon-history:before {
  content: '\ea54';
}
.icon-drawer:before {
  content: '\ea55';
}
.icon-undo2:before {
  content: '\ea56';
}
.icon-hour-glass:before {
  content: '\ea57';
}
.icon-wecare-make-group:before {
  content: '\ea58';
}
.icon-shrink:before {
  content: '\ea59';
}
.icon-earth:before {
  content: '\ea5a';
}
.icon-happy:before {
  content: '\ea5b';
}
.icon-smile:before {
  content: '\ea5c';
}
.icon-sad:before {
  content: '\ea5d';
}
.icon-neutral:before {
  content: '\ea5e';
}
.icon-wondering:before {
  content: '\ea5f';
}
.icon-wecare-logo:before {
  content: '\ea60';
}
.icon-kaizen:before {
  content: '\ea61';
}
.icon-add-partial-report:before {
  content: '\ea62';
}
.icon-sherlock:before {
  content: '\ea63';
}
.icon-wecare-italic:before {
  content: '\ea64';
}
.icon-rdws-water:before {
  content: '\ea65';
}
.icon-rdws-map:before {
  content: '\ea66';
}
.icon-rdws-drainage:before {
  content: '\ea67';
}
.icon-rdws-charging:before {
  content: '\ea68';
}
.icon-rdws-pumping-station:before {
  content: '\ea69';
}
.icon-water:before {
  content: '\ea6a';
}
.icon-move:before {
  content: '\ea6b';
}
.icon-arrow-right:before {
  content: '\ea6c';
}
.icon-arrow-right2:before {
  content: '\ea6d';
}
.icon-make-group:before {
  content: '\ea6e';
}
.icon-italic:before {
  content: '\ea6f';
}
.icon-rdws-additional-questions:before {
  content: '\ea70';
}
.icon-tick-mark:before {
  content: '\ea71';
}
.icon-map-property-remove:before {
  content: '\ea72';
}
.icon-fast-track-remove:before {
  content: '\ea73';
}
.icon-wecare-supplier-logo:before {
  content: '\ea74';
}
.icon-bob:before {
  content: '\ea75';
}
.icon-itop5:before {
  content: '\ea76';
}
.icon-link:before {
  content: '\ea77';
}
.icon-bullseye:before {
  content: '\ea78';
}
.icon-data-research-notes:before {
  content: '\ea79';
}
.icon-user-groups:before {
  content: '\ea7a';
}
.icon-auto-search:before {
  content: '\ea7b';
}
.icon-tag:before {
  content: '\ea7c';
}
.icon-ace-auto-allocation:before,
.icon-ace-settings:before,
.icon-ace:before {
  content: "\ea7d";
}
.icon-auto-mark:before {
  content: "\ea7e";
}
